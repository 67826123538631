/** @jsxImportSource theme-ui */

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { BaseStyles, ThemeUICSSObject } from "theme-ui";
import ExternalLink from "../components/external-link";
import Global from "../components/global";
import { Header } from "../components/header";
import Main from "../components/main";
import Seo from "../components/seo";
import Wrapper from "../components/wrapper";

const TITLE = "このサイトについて";

export const Head = () => <Seo title={TITLE} article={true} />;

const About = () => {
  return (
    <>
      <Global />
      <Header />
      <Main>
        <Wrapper>
          <BaseStyles>
            <div sx={block}>
              <p>地域の郷土芸能を地区ホールなどで一度に公演する「郷土芸能発表会」「民俗芸能大会」といった催しが各地で開催されています。</p>
              <p>
                その地域の芸能を一度に見学できて便利なので、自分の所はもとより他の地域の発表会にもよく出かけたりするのですが、あちこち行きすぎてだんだん日程を把握しきれなくなってきました。<br />
                しかも、このような催しは基本的にその地域の人向けなので、地区の広報などにしか情報が掲載されないことが多いです。結果として、「あっ、○×市の芸能大会ってそろそろだっけ？」と思って慌てて調べてみると、先週末に開催されてた、なんてこともしばしば…。
              </p>
              <p>
                ということできちんとスケジュールを管理できるよう、自分が知っている限りの発表会の日程を調べてリスト化しました。今後、定期的に更新できればいいなと思っています。
              </p>
            </div>

            <h2>注意事項</h2>
            <ul sx={list}>
              <li>
                私（サイト管理人）がよく見学する <b>祭囃子・神楽・獅子舞</b> を含む発表会のみリスト化しています。<br />
                郷土芸能と聞いて思い浮かべる和太鼓や民謡や日本舞踊などの発表会は掲載していません。すみません。
              </li>
              <li>複数の団体の公演を一度に見ることが主眼ですので、一つの団体のみの公演・発表会は調査していません。</li>
              <li>
                <b>このサイトに掲載されている内容は間違っているかも知れません。</b>
                また、悪天候などの理由で中止になることもありますので、お出かけの際は主催者に内容をご確認下さい。
              </li>
              <li>古い情報はリンク切れになっているかも知れませんが、どうしようもないのでご了承下さい。</li>
              <li>
                このサイトはBeta版です。サイト製作者の多忙<span sx={smallText}>（仕事が、ではなく、祭り見物で、かも知れませんが -_-;）</span>
                やその他の理由<span sx={smallText}>（飽きたとか…）</span>により、予告することなく更新が止まる可能性があります。ご了承下さい。
              </li>
            </ul>

            <h2>管理人について</h2>
            <div sx={block}>
              <StaticImage sx={profileImage} width={320} src="../../content/images/profile_2020.jpg" alt="プロフィール画像" />
              <p>ハンドルネーム：やのけん</p>
              <p>
                獅子舞ハンター。基本的に獅子舞が好き。<br />
                既に子供の頃からなぜか獅子舞に興味を持っていたらしい。2013年からは東京都内のとある囃子の会にて自分でも獅子舞を習い始める。<br />
                初めて訪れた郷土芸能大会は、2008年の「江東区民まつり 中央まつり 民俗芸能大会」でした。（砂村囃子睦会さんの獅子舞を見に行きました！）
              </p>
              <p>
                サイトについて何かありましたらメールアドレス「yanoken850p アットマーク gmail.com」もしくはX（旧ツイッター）のDMにどうぞ。<br />
                Xは <ExternalLink url="https://twitter.com/tabigarasu24">https://twitter.com/tabigarasu24</ExternalLink> でやっています。
              </p>
              <div style={{ clear: "both" }}></div>
              ↓ちなみに今までで一番動きがマシだった時の動画です。
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  今年の元日0時0分に獅子をやらせてもらった時の動画。
                  <a href="https://twitter.com/hashtag/%E7%A5%AD%E5%9B%83%E5%AD%90?src=hash&amp;ref_src=twsrc%5Etfw">#祭囃子</a>{" "}
                  <a href="https://twitter.com/hashtag/%E7%8D%85%E5%AD%90%E8%88%9E?src=hash&amp;ref_src=twsrc%5Etfw">#獅子舞</a>{" "}
                  <a href="https://twitter.com/hashtag/%E5%85%AB%E7%8E%8B%E5%AD%90%E5%B8%82?src=hash&amp;ref_src=twsrc%5Etfw">#八王子市</a>{" "}
                  <a href="https://t.co/O2RfI9g5Rw">pic.twitter.com/O2RfI9g5Rw</a>
                </p>&mdash; やのけん/850 (@tabigarasu24){" "}
                <a href="https://twitter.com/tabigarasu24/status/1219287321402413056?ref_src=twsrc%5Etfw">January 20, 2020</a>
              </blockquote>
              また、管理人が所属する祭囃子の会「八櫻會」では、獅子舞や祭囃子の公演依頼を承っております。 詳細は{" "}
              <ExternalLink url="https://myshishilist.wordpress.com/hachiokai_shokai/" title="獅子舞・祭囃子のご依頼について 〜 八櫻會の紹介ページ">
                こちらのページ
              </ExternalLink>{" "}
              をどうぞ！
            </div>

            <h2>リンクについて</h2>
            <p>このサイトへのリンクはご自由にどうぞ。</p>

            <h2>関連サイトのご案内</h2>
            <div sx={block}>
              <ul sx={list}>
                <li>
                  <ExternalLink url="https://myshishilist.wordpress.com/" title="お正月獅子舞一覧">お正月獅子舞一覧</ExternalLink>
                  <br />
                  <div sx={linkUrl}>
                    <ExternalLink url="https://myshishilist.wordpress.com/" title="https://myshishilist.wordpress.com/">
                      https://myshishilist.wordpress.com/
                    </ExternalLink>
                  </div>
                  <p>
                    サブサイト。関東地方でお正月に行われる獅子舞イベントをリスト化しています。<br />
                    これまでに訪れた大会や祭礼でのX（旧ツイッター）のまとめもあります。
                  </p>
                </li>
                <li>
                  <ExternalLink url="https://shishi-photo.jp/" title="獅子舞写真bot">獅子舞写真bot</ExternalLink>
                  <br />
                  <div sx={linkUrl}>
                    <ExternalLink url="https://shishi-photo.jp/" title="https://shishi-photo.jp/">https://shishi-photo.jp/</ExternalLink>
                  </div>
                  <p>
                    皆様にアップロードして頂いた獅子舞やシシオドリ等の写真をX（旧ツイッター）に自動投稿するボットです。<br />
                    アップロードしていただくと、 <ExternalLink url="https://twitter.com/shishi_photoB" title="獅子舞写真bot">こちらのアカウント</ExternalLink>
                    {" "}
                    にてツイートされます。
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <Link to="/">トップページに戻る</Link>
            </div>
          </BaseStyles>
        </Wrapper>
      </Main>
    </>
  );
};

export default About;

const block = {
  marginBottom: "3.0rem",
};

const list = {
  listStyleType: "disc",

  "li": {
    marginTop: "0.5rem",
    marginLeft: "1.5rem",
  },
};

const smallText = {
  fontSize: "0.8rem",
};

const profileImage: ThemeUICSSObject = {
  "@media (min-width: 601px)": {
    float: "left",
    marginRight: "1.5rem",
  },

  "@media (max-width: 600px)": {
    margin: "0 auto 1.0rem auto",
    width: "80%",
  },
};

const linkUrl = {
  margin: "0.3rem 0",
};
